<template>
  <a-modal
    :destroyOnClose="true"
    title="添加/编辑用户"
    :width="800"
    :visible="visible"
    :confirmLoading="addloading"
    @ok="saveUserApi"
    @cancel="cancelForm"
  >
    <a-spin :spinning="addloading">
      <a-form :form="form" v-bind="formLayout">
        <!-- 检查是否有 id 并且大于0，大于0是修改。其他是新增，新增不显示主键ID -->
        <a-form-item
          label="手机号"
          :validateStatus="checkParams.mobileStatus"
          :help="checkParams.mobileStatusMsg"
        >
          <a-input-search
            v-decorator="['mobile', {initialValue: userInfoParam.mobile, rules: [{required: true, validator: verifyMobile, validateTrigger: 'change'}]}]"
            :disabled="disabled"
            :maxLength="11"
            @search="onSearch"
            @change="onSearch"
          />
        </a-form-item>
        <a-form-item
          label="用户名"
          :validateStatus="checkParams.userNameStatus"
          :help="checkParams.userNameStatusMsg"
        >
          <a-input
            v-decorator="['userName', {initialValue: userInfoParam.userName, rules: [{required: true, validator: verifyUserName, validateTrigger: 'change'}]}]"
            :disabled="mdisabled"
          />
        </a-form-item>
        <a-form-item label="真实姓名">
          <a-input
            v-decorator="['realName', { initialValue: userInfoParam.realName }]"
            @change="changeRealName"
          />
        </a-form-item>
        <a-form-item label="邮箱">
          <a-input
            v-decorator="['email', { initialValue: userInfoParam.email }]"
            @change="changeEmail"
          />
        </a-form-item>
        <a-form-item label="性别">
          <a-radio-group
            v-decorator="['userSex', {initialValue: userInfoParam.userSex, rules: [{required: true}]}]"
            style="width: 100%"
            @change="changeUserSex"
          >
            <a-radio :value="0">未知</a-radio>
            <a-radio :value="1">男</a-radio>
            <a-radio :value="2">女</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="选择角色">
          <a-col :md="10" :sm="10" v-for="(item, index) in conditionlist.roleList" :key="index">
            <a-checkbox
              :value="item.roleId"
              :checked="roleIdArr.indexOf(item.roleId) > -1 ? true : false"
              @change="roleChange"
              :disabled="(currentUser.id === userInfo.userId) ? true : false"
            >
              {{ item.roleName }}
            </a-checkbox>
          </a-col>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
// import pick from 'lodash.pick'
import { checkUserInfo, saveData, userCondition, userData } from '@/api/user'
// 表单字段
// const fields = ['userName', 'mobile', 'realName', 'email', 'userSex']

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      }
    }
    return {
      form: this.$form.createForm(this, { name: 'searchForm' }),
      conditionlist: {
        statusList: [],
        roleList: []
      },
      userInfo: {
        userId: 0,
        userName: '',
        email: '',
        userSex: 0,
        realName: ''
      },
      userInfoParam: {
        userId: 0,
        userName: '',
        email: '',
        userSex: 0,
        realName: ''
      },
      roleIdArr: [],
      disabled: false,
      mdisabled: false,
      currentUser: {},
      checkParams: {
        userNameStatus: 'success',
        userNameStatusMsg: null,
        isUserName: false,
        mobileStatus: 'success',
        mobileStatusMsg: null,
        isMobile: false
      },
      addloading: false
    }
  },
  created () {
    this.userConditionApi()
    this.currentUser = this.$store.state.user.info
    // 防止表单未注册
    // fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    /* this.$watch('dataItem', () => {
      this.dataItem && this.form.setFieldsValue(pick(this.dataItem, fields))
    }) */
  },
  methods: {
    userConditionApi () {
      const _this = this
      userCondition({}).then((res) => {
        if (res.errorCode === 0) {
          _this.conditionlist = res.result
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    getUserInfo (userInfo) {
      console.log(userInfo)
      const _this = this
      _this.addloading = true
      _this.roleIdArr = []
      _this.userInfo = userInfo
      if (_this.userInfo.userId === 0) {
        _this.disabled = false
        _this.mdisabled = false
        _this.roleIdArr = []
        _this.userInfo = {
          userId: 0,
          userName: '',
          email: '',
          userSex: 0,
          realName: ''
        }
        _this.userInfoParam = {
          userId: 0,
          userName: '',
          email: '',
          userSex: 0,
          realName: ''
        }
        _this.addloading = false
      } else {
        userData({ userId: this.userInfo.userId }).then((res) => {
          if (res.errorCode === 0) {
            _this.userInfo = res.result
            _this.userInfoParam = res.result
            _this.roleIdArr = _this.userInfo.roleIdArr
          } else {
            this.$message.error('系统错误')
          }
          _this.addloading = false
        }).catch((err) => {
          console.log(err)
          _this.addloading = false
        })

        _this.disabled = true
        if (_this.userInfo.userName !== '') {
          _this.mdisabled = true
        } else {
          _this.mdisabled = false
        }
      }
      console.log('getUserInfo', userInfo, _this.roleIdArr)
    },
    roleChange (e) {
      console.log('roleChange', e)
      if (e.target.checked) {
        this.roleIdArr.push(e.target.value)
      } else {
        var index = this.roleIdArr.indexOf(e.target.value)
        if (index > -1) {
          this.roleIdArr.splice(index, 1)
        }
      }
    },
    saveUserApi () {
      console.log('saveUserApi')
      const _this = this
      const mobileReg = /^1[0-9]\d{9}$/
      if (mobileReg.test(_this.userInfoParam.mobile)) {
        _this.checkParams.mobileStatus = 'success'
        _this.checkParams.mobileStatusMsg = null
        _this.checkParams.isMobile = true
      } else {
        _this.checkParams.mobileStatus = 'error'
        _this.checkParams.mobileStatusMsg = '请输入正确的手机号码'
        _this.checkParams.isMobile = false
        return false
      }

      if (_this.userInfoParam.userName === '') {
        this.checkParams.userNameStatus = 'error'
        this.checkParams.userNameStatusMsg = '请输入用户名'
        this.checkParams.isUserName = false
        return false
      } else {
        if (_this.userInfoParam.userName.length < 2 || _this.userInfoParam.userName.length > 15) {
          this.checkParams.userNameStatus = 'error'
          this.checkParams.userNameStatusMsg = '用户名长度为2-15'
          this.checkParams.isUserName = false
          return false
        } else {
          this.checkParams.userNameStatus = 'success'
          this.checkParams.userNameStatusMsg = null
          this.checkParams.isUserName = true
        }
      }
      _this.userInfoParam.userId = _this.userInfo.userId
      _this.userInfoParam.roleIdArr = _this.roleIdArr
      console.log('saveUserApi', _this.userInfoParam)
      _this.addloading = true
      saveData(_this.userInfoParam).then((res) => {
        if (res.errorCode === 0) {
          if (_this.userInfoParam.userId > 0) {
            _this.$message.success('更新成功')
          } else {
            _this.$message.success('添加成功')
          }
          _this.addloading = false
          setTimeout(() => {
            this.$emit('ok')
          }, 1000)
        } else {
          _this.addloading = false
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.addloading = false
        console.log(err)
      })
    },
    cancelForm () {
      this.roleIdArr = []
      this.userInfoParam.roleIdArr = []
      console.log('cancelForm', this.roleIdArr)
      this.$emit('cancel')
    },
    verifyUserName (rule, value, callback) {
      var userName = value.trim()
      this.userInfoParam.userName = value.trim()
      if (userName === '') {
        this.checkParams.userNameStatus = 'error'
        this.checkParams.userNameStatusMsg = '请输入用户名'
        this.checkParams.isUserName = false
        callback(new Error('请输入用户名'))
      } else {
        if (userName.length < 2 || userName.length > 15) {
          this.checkParams.userNameStatus = 'error'
          this.checkParams.userNameStatusMsg = '用户名长度为2-15'
          this.checkParams.isUserName = false
          callback(new Error('用户名长度为2-15'))
        } else {
          this.checkParams.userNameStatus = 'success'
          this.checkParams.userNameStatusMsg = null
          this.checkParams.isUserName = true
        }
      }
    },
    verifyMobile (rule, value, callback) {
      const mobileReg = /^1[0-9]\d{9}$/
      const _this = this
      _this.userInfoParam.mobile = value.trim()
      if (mobileReg.test(value)) {
        _this.checkParams.mobileStatus = 'success'
        _this.checkParams.mobileStatusMsg = null
        _this.checkParams.isMobile = true
      } else {
        _this.checkParams.mobileStatus = 'error'
        _this.checkParams.mobileStatusMsg = '请输入正确的手机号码'
        _this.checkParams.isMobile = false
        callback(new Error('手机号格式不正确'))
      }
    },
    changeRealName (e) {
      this.userInfo.realName = e.target.value.trim()
      this.userInfoParam.realName = e.target.value.trim()
    },
    changeEmail (e) {
      this.userInfo.email = e.target.value.trim()
      this.userInfoParam.email = e.target.value.trim()
    },
    changeUserSex (e) {
      this.userInfo.userSex = e.target.value
      this.userInfoParam.userSex = e.target.value
    },
    onSearch (e) {
      const _this = this
      // console.log('onSearch', e.target.value)
      var mobile = e.target.value.trim()
      const mobileReg = /^1[0-9]\d{9}$/
      if (mobileReg.test(mobile)) {
        console.log('onSearch111', e.target.value)
        checkUserInfo({ mobile: mobile }).then((res) => {
          if (res.errorCode === 0) {
            if (res.result.userId) {
              _this.userInfo = res.result
              _this.userInfoParam = res.result
              _this.roleIdArr = _this.userInfo.roleIdArr
            } else {
              _this.userInfo.userId = 0
              _this.userInfo.mobile = mobile
              _this.userInfo.userName = ''
              _this.userInfo.email = ''
              _this.userInfo.userSex = 0
              _this.userInfo.realName = ''
              _this.userInfoParam.mobile = mobile
              _this.userInfoParam.userId = 0
              _this.userInfoParam.userSex = 0
              _this.userInfoParam.realName = ''
              _this.userInfoParam.userName = ''
              _this.userInfoParam.email = ''
              _this.roleIdArr = []
            }
          } else {
            _this.$message.error(res.errorMsg)
          }
        }).catch((err) => {
          console.log(err)
        })
      }
    }
  }
}
</script>
